import logo from './logo.svg';
import './App.css';
import homeImg from './img/home.jpg';
import aboutImg from './img/about.jpg';
import floretBox from './img/floret_box.png';
import flower2 from './img/gallery2.jpg';
import React, { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive'
import { slide as Menu } from 'react-burger-menu'
import { SocialIcon } from 'react-social-icons';
import floretBauble from './img/gallery_bauble.jpg';
import floretDome from './img/home.jpg';
import floretVase from './img/gallery_vase.jpg'
import floretBasket from './img/basket.jpg'

function App() {
  return (
    <div className="App">
      <Navigation />
      <Route path="/">
        <HomePage />
      </Route>
      <Route path="/about">
        <AboutPage />
      </Route>
      <Route path="/gallery">
        <GalleryPage />
      </Route>

      <Route path="/values">
        <ValuesPage />
      </Route>
      <div className="Footer">
        <div className="ContactContainer">
        <div><SocialIcon url="mailto:littlefloret.perth@gmail.com" /></div>
        <div style={{ padding: "0 1.25rem"}}><SocialIcon url="https://www.instagram.com/littlefloret.perth/" /></div>
        <div><SocialIcon url="https://www.facebook.com/littlefloret.perth/" /></div>
      </div>
      </div>

    </div>
  );
}

const Navigation = () => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // define callback as separate function so it can be removed later with cleanup function
    const onLocationChange = () => {
      setCurrentPath(window.location.pathname);
    }
    window.addEventListener('popstate', onLocationChange);
    // clean up event listener
    return () => {
      window.removeEventListener('popstate', onLocationChange)
    };
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });

  const closeNav = () => {
    console.log("called!")
    setMenuOpen(false);
  }

  if (!isDesktopOrLaptop) {
    return (
      <div className="MobileMenuWrapper">
        <img src={logo} className="LittleFloretLogo2" alt="Little Floret Logo" />
          <Menu isOpen={menuOpen} onOpen={() => setMenuOpen(true)}>
            <Link OnNavigate={closeNav} className="NavigationItem" href="/">
              <div className={(currentPath === "/") ? "NavigationActive" : ""}>Home</div>
            </Link>
            <Link OnNavigate={closeNav} className="NavigationItem" href="/about">
              <div className={(currentPath === "/about") ? "NavigationActive" : ""}>About</div>
            </Link>
            <Link OnNavigate={closeNav} className="NavigationItem" href="/values">
              <div className={(currentPath === "/values") ? "NavigationActive" : ""}>Values</div>
            </Link>
            <Link OnNavigate={closeNav} className="NavigationItem" href="/gallery">
              <div className={(currentPath === "/gallery") ? "NavigationActive" : ""}>Gallery</div>
            </Link>
          </Menu>
      </div>
    )
  }

  return (
    <div className="Navigation">
      <img src={logo} className="LittleFloretLogo" alt="Little Floret Logo" />
      <Link className="NavigationItem" href="/">
        <div className={(currentPath === "/") ? "NavigationActive" : ""}>Home</div>
      </Link>
      <Link className="NavigationItem" href="/about">
        <div className={(currentPath === "/about") ? "NavigationActive" : ""}>About</div>
      </Link>
      <Link className="NavigationItem" href="/values">
        <div className={(currentPath === "/values") ? "NavigationActive" : ""}>Values</div>
      </Link>
      <Link className="NavigationItem" href="/gallery">
        <div className={(currentPath === "/gallery") ? "NavigationActive" : ""}>Gallery</div>
      </Link>
    </div>

  );

}

const Link = ({ className, href, children, OnNavigate }) => {
  const onClick = (event) => {
    event.preventDefault();
    window.history.pushState({}, "", href);
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
    OnNavigate();
  }

  return (
    <a className={className} href={href} onClick={onClick}>
      {children}
    </a>
  );
};

const Route = ({ path, children }) => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  useEffect(() => {
    // define callback as separate function so it can be removed later with cleanup function
    const onLocationChange = () => {
      setCurrentPath(window.location.pathname);
    }
    window.addEventListener('popstate', onLocationChange);
    // clean up event listener
    return () => {
      window.removeEventListener('popstate', onLocationChange)
    };
  }, [])

  return currentPath === path
    ? children
    : null;
};


const HomePage = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });

  return (
    <div className="HomePageContainer" style={!isDesktopOrLaptop ? {display: "block"} : {}}>
      <div style={isDesktopOrLaptop ? { padding: "5rem", paddingTop: "0", paddingRight: "0" } : {paddingTop: "1.25rem"}}>
        <img alt="" className="HomePageImg" src={homeImg} style={!isDesktopOrLaptop ? {height: "200px", width: "150px"} : {}} />
      </div>
      <div className="HomePageInformation">
        <h1>Little Floret</h1>
        {/* <div style={isDesktopOrLaptop ? {} : {marginTop: "1.25rem"}} className="HomePageTitle">Little Floret</div> */}
        <div className="HomePageContents">
          Here at Little Floret, you will find Dried Flower creations curated with locally sourced flora and fauna, ethical materials and hand crafted with care. By displaying the dried flower arrangements  in an enclosed glass dome, it highlights the precious beauty of each flower floret and the appreciation of the incredible unique nature around us.
        </div>
        <br />
        <div style={{ paddingBottom: "5rem"}}>
          Floret Domes
          .
          Floret Baubles
          .
          Floret Vases
          .
          Floret Boxes
        </div>
      </div>
    </div>
  );
}

const AboutPage = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });

  return (
  <div className="AboutContainer" style={!isDesktopOrLaptop ? {display: "block"} : {}}>
    <div style={isDesktopOrLaptop ? { padding: "5rem", paddingTop: "0", paddingRight: "0" }: {paddingTop: "1.25rem"}}>
      <img alt="" className="HomePageImg" src={aboutImg} style={!isDesktopOrLaptop ? {height: "200px", width: "150px"} : {}} />
    </div>
    <div className="AboutInformation">
      <div className="AboutContents">
        <p>
          Little floret was created out of love, curiosity and admiration for the natural flora and fauna found around us. Capturing the beauty and highlighting each stem, flower and floret in a glass dome.
        </p>

        <p>
          The name ‘Little Floret’ was derived from my personal moniker of ‘Little Jy’ (Little Jenny). I am short in stature and love all things pretty in pink, hello kitty and flowers. Floret is also a term for a small flower that joins with other florets to make up a flower. It signifies the beauty in the small things and when joined together with others, create something even more beautiful.
        </p>
        <p>
          My interest in flowers stems from my mum, who has been potting around the garden all her life, with me as her gardening assistant. She is still my partner in crime and has joined me in my many foraging adventures around Perth.
        </p>
        <p>
          Love, Jenny
        </p>
      </div>
      <button className="AboutButton" type="button" onClick={() => window.open("mailto:littlefloret.perth@gmail.com")}>
        Work with Me!
      </button>
    </div>
  </div>
);
}

const GalleryPage = () => {
   const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });

  return (
  <div className="GalleryWrapper">
     <div className="GalleryContainer" style={!isDesktopOrLaptop ? {flex: "1 1 50%"} : {}}>
      <div className="GalleryItemWrapper" style={!isDesktopOrLaptop ? {flex: "1 1 50%"} : {}}>
        <img alt="" className="GalleryItem" src={floretDome} />
        <div>Floret Dome</div>
      </div>
      <div className="GalleryItemWrapper" style={!isDesktopOrLaptop ? {flex: "1 1 50%"} : {}}>
        <img alt="" className="GalleryItem" src={floretBauble} />
        <div>Floret Baubles</div>
      </div>
      <div className="GalleryItemWrapper" style={!isDesktopOrLaptop ? {flex: "1 1 50%"} : {}}>
        <img alt="" className="GalleryItem" src={floretVase} />
        <div>Floret Vases</div>
      </div>
       <div className="GalleryItemWrapper" style={!isDesktopOrLaptop ? {flex: "1 1 50%"} : {}}>
        <img alt="" className="GalleryItem" src={floretBox} />
        <div>Floret Box</div>
      </div>
      <div className="GalleryItemWrapper" style={!isDesktopOrLaptop ? {flex: "1 1 50%"} : {}}>
        <img alt="" className="GalleryItem" src={flower2} />
        <div>Floret Bouquet</div>
      </div>
      <div className="GalleryItemWrapper" style={!isDesktopOrLaptop ? {flex: "1 1 50%"} : {}}>
        <img alt="" className="GalleryItem" src={floretBasket} />
        <div>Floret Basket</div>
      </div>
    </div>
  </div>
);

}
const ValuesPage = () => (
  <div className="ValuesContainer">
    <h2 style={{ marginTop: "0.5rem"}}>Sustainability</h2>
    <div style={{ fontSize: "1.25rem "}}>
      Through my flower journey I have been keen to choose appropriate ways to continue to nurture the environment around us. I am still on the learning journey of best practices and new ways to incorporate and give new life to the things we have around us.
    </div>
    <p style={{ fontSize: "1.25rem "}}> My current practises are:</p>
    <div className="PracticeList" style={{ fontSize: "1.25rem "}}>
      <li>Re-using old fabrics and old ribbon to tie my floral creations.</li>
      <li>Choosing Glass over plastic when selecting products to display my work.</li>
      <li>Repurposing old tin canisters, beauty containers and jars giving them a new life.</li>
      <li>Re-using my Dad’s chinese calligraphy practice sheets as wrapping for my products.</li>
      <li>Collecting the small florets and buds that have fallen to create smaller creations.</li>
      <li>Collecting fallen branches and flowers during the windy seasons to use in my creations.</li>
    </div>
    <br />
    <h2>Ethical Practises</h2>
    <div style={{ fontSize: "1.25rem "}}>
      The flower industry is full of amazing flowers and creations. I have chosen to highlight the beauty of Australian natives and the locally grown. I have been sourcing my flowers locally and from Australian flower farms. I have access to properties around Perth for Australian native greenery, seasonal flowers  grow my own flowers and support the local flower farms.
    </div>
    <p style={{ fontSize: "1.25rem "}}>
      I have slowly transitioned to using original flower colourings and eco-friendly flower dyeing processes to create the variety of flower colours. This is still an aspect I am currently still researching and learning about.
    </p>
    < br/>
    <h2><a className="ExternalLink" href="https://grown-not-flown.com/">Grown Not Flown (GNF)</a></h2>
    <div style={{ fontSize: "1.25rem "}}>
      Grown not flown is an initiative I support and use as a source to find flower farms to contact for flowers. Grown Not Flown’s goal is to promote locally grown blooms, showcasing the seasonal flowers available on our doorstep and making it easier than ever to connect and support growers no matter how big or small. It is about increasing the choices available to consumers whether they are wholesalers, florists, hospitality, event stylists, DIY brides or everyday flower lovers.
    </div>
    <br />
    <br />
  </div>
);

export default App;
